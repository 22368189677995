import coffecup from '../Images/coffespecialist.png';
import artisan from '../Images/artisan.jpg';
import rental from '../Images/rental.jpg';


const specialites = [
    {
        Image: coffecup, 
        Title: 'Coffee Products',
        Description: 'Indulge in our expertly brewed coffee, made from the finest beans and crafted to perfection.',
    },
    {
        Image: artisan, 
        Title: 'Artisan Doughnuts',
        Description: 'Treat yourself to our handmade doughnuts, each one a delightful blend of flavor and texture.',
    },
    {
        Image: rental, 
        Title: 'Catering Services',
        Description: 'From intimate gatherings to grand celebrations, our catering services bring gourmet flavors to your events.',
    }
];

export default specialites; // ES6 export default
